import { APICore } from "./apiCore";

const api = new APICore();

interface Params {
  [key: string]: string;
}

// account
function getRealEstatePricesData(params: Params) {
  const baseUrl = "/api/v1/real_estate/prices";
  return api.get(`${baseUrl}`, params);
}

function getRealEstateOptions(params: Params) {
  const baseUrl = "/api/v1/real_estate/options";
  return api.get(`${baseUrl}`, params);
}

// city
// async function getCityList(countryCod: string, params: Params) {
//   const baseUrl = "/api/v1/geo";
//   const cityOptions: { value: string; label: string; order: number }[] = [];
//   const result = await api.get(`${baseUrl}/${countryCod}`, params);
//   const cityDataRecursion = (obj: any) => {
//     if (obj.cities) {
//       const { cities } = obj;
//       for (const city in cities) {
//         cityOptions.push({
//           value: cities[city].id,
//           label: cities[city].name,
//           order: cities[city].order,
//         });
//       }
//     } else {
//       for (const prop in obj) {
//         if (typeof obj[prop] === "object") {
//           cityDataRecursion(obj[prop]);
//         }
//       }
//     }
//   };

//   cityDataRecursion(result.data);

//   cityOptions.sort((a, b) => b.order - a.order);

//   const topOrder = cityOptions.slice(0, 20);
//   const otherCity = cityOptions.slice(20).sort((a, b) => a.label.localeCompare(b.label));

//   return [...topOrder, ...otherCity];
// }

function getCityList(countryCod: string, params: Params) {
  const baseUrl = "/api/v1/geo";
  return api.get(`${baseUrl}/${countryCod}`, params);
}

//hexId
function getRealEstateReport(params: Params) {
  const baseUrl = "/api/v1/reports/show";
  return api.get(`${baseUrl}`, params);
}

export { getRealEstatePricesData, getRealEstateOptions, getCityList, getRealEstateReport };
