//types
import { Report } from "../../types/api/realEstateResponseTypes";

export const SET_REAL_ESTATE_REPORT_FILTERS = "SET_REAL_ESTATE_REPORT_FILTERS";
export const SET_REAL_ESTATE_REPORT_H3_CELLS = "SET_REAL_ESTATE_REPORT_H3_CELLS";
export const SET_REAL_ESTATE_REPORT_RESPONSE = "SET_REAL_ESTATE_REPORT_RESPONSE";

interface FiltersParams {
  [key: string]: string;
}

interface H3 {
  cells: string[] | null;
  resolution: number | null;
}

export const setRealEstateReportFilters = (filtersParams: FiltersParams) => ({
  type: SET_REAL_ESTATE_REPORT_FILTERS,
  payload: filtersParams,
});

export const setRealEstateReportH3Cells = (h3: H3) => ({
  type: SET_REAL_ESTATE_REPORT_H3_CELLS,
  payload: h3,
});

export const setRealEstateReportResponse = (reportedData: Report) => ({
  type: SET_REAL_ESTATE_REPORT_RESPONSE,
  payload: reportedData,
});
